import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["storeInfo"]),

    /**
     * Verifica de há features no "storeInfo"
     * @return {bool}
     */
    hasfeatures() {
      return this.storeInfo.features && this.storeInfo.features.length > 0;
    },
  },

  methods: {
    /**
     * Verifica se a toggle passada esta ativa na loja
     *
     * @param {string} featureToggle
     * @return {bool}
     */
    isToggleEnabled(featureToggle) {
      const { features } = this.storeInfo;
      return this.hasfeatures ? features.indexOf(featureToggle) !== -1 : false;
    },
  },
};
